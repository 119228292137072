import React from "react";
import style from "./BulletPoint.module.css";

const BulletPoint = () =>{
    return(
        <div className={style.bullet_point}></div>
    )

}

export default BulletPoint;